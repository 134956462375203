import { useReducer } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import {
  faArrowRightFromBracket,
  faCircleChevronRight,
  faSchool,
  faUsers,
  faUser,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();

  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }

  return (
    <section className="flex flex-row-reverse h-screen max-h-screen w-full overflow-hidden bg-light dark:bg-dark dark:text-light md:pt-32">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-32 w-full items-center justify-between bg-white px-16 text-light md:flex">
        <div className="logo h-fit">
          <img
            className="h-24 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/dashboard")}
            src="https://api.nash-academy.com/uploads/assets/logo.png"
            alt="logoDark"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
      </section>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto">
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-32" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-32" : "w-1/5 "
        } fixed left-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : "md:pointer-events-none  md:translate-x-20 md:opacity-0"
                    } md:pt-32`}
      >
        <div className="collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute right-0 top-20 z-50  translate-x-4 rotate-180 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-0"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div class="min-h-screen flex flex-row bg-gray-100">
          <div class="dash-menu-container flex flex-col w-full bg-white rounded-r-3xl overflow-hidden">
            <div class="flex items-center justify-center shadow-md logo h-fit md:hidden">
              <img
                className={` ${
                  state.collapse ? "h-32" : " w-3/4"
                }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
                onClick={() => navigate("/")}
                src="https://api.nash-academy.com/uploads/assets/logo.png"
                alt="logo-dash-light"
              />
            </div>
            <ul class="dash-menu-items  flex flex-col items-center gap-5 mt-8">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("students")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/students"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary `}
                  >
                    {/* <div className="text-icon  flex w-full items-center"> */}
                    <FontAwesomeIcon className="w-8" icon={faSchool} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Students
                    </p>
                    {/* </div> */}
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("courses")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/courses"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary  `}
                  >
                    <FontAwesomeIcon className=" w-8" icon={faFileAlt} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Courses
                    </p>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("est")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/est-materials"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }    rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary`}
                  >
                    <FontAwesomeIcon className="w-8" icon={faFileAlt} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      EST
                    </p>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("act")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/act-materials"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }   rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary `}
                  >
                    <FontAwesomeIcon className="w-8" icon={faFileAlt} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      ACT
                    </p>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("dsat")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/dsat-materials"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }  rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary `}
                  >
                    <FontAwesomeIcon className="w-8" icon={faFileAlt} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      DSAT
                    </p>
                  </NavLink>
                </li>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("homeschooling")
                )) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/homeschooling"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }  rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary `}
                  >
                    <FontAwesomeIcon className="w-8" icon={faFileAlt} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Homeschooling Admission
                    </p>
                  </NavLink>
                </li>
              )}

              {AuthState.userData?.admin?.roles[0]?.name === "superadmin" && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="flex h-full w-full justify-center"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    to="/admin/assistants"
                    className={`group flex h-16 w-9/12  alg:w-11/12 ${
                      state.collapse
                        ? "items-center justify-center px-0"
                        : "items-center px-5 gap-5"
                    }  rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary`}
                  >
                    <FontAwesomeIcon className="w-8" icon={faUsers} />
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      Assistants
                    </p>
                  </NavLink>
                </li>
              )}
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/profile"
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center px-5 gap-5"
                  }   rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary`}
                >
                  <FontAwesomeIcon
                    className=" w-8 text-3xl font-bold"
                    icon={faUser}
                  />
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    Profile
                  </p>
                </NavLink>
              </li>
              <li className="log-out flex h-fit  w-full justify-center">
                <button
                  onClick={() => handleSignOut()}
                  className={`group flex h-16 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center px-5 gap-5"
                  }    rounded-3xl transition-all duration-200 ease-in-out transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-secondary `}
                >
                  <FontAwesomeIcon
                    className=" w-8 rotate-180"
                    icon={faArrowRightFromBracket}
                  />
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    } `}
                  >
                    Log out
                  </p>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminLayout;
