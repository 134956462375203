import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";

const QuizFooter = ({
  state,
  dispatch,
  onFinishQuiz,
  isParagraphe,
  quizData,
}) => {
  return (
    <footer className="amd-hero:gap-[16px]  mt-[40px]  flex  w-full  items-center justify-between gap-[32px] rounded-lg bg-secondary p-[20px] ">
      <div className="numbering flex w-[160px] justify-center gap-[4px] rounded-md bg-light  p-[8px]  text-secondary md:w-[64px]">
        {!isParagraphe ? (
          <>
            <span className="current-question-number">
              {state.questions.length}
            </span>
            <span className="font-bold md:hidden">Questions</span>
          </>
        ) : (
          <>
            <span className="font-bold md:hidden">Section</span>
            <span className="current-question-number">
              {state.currentSectionIndex}
            </span>
            <span className="font-bold">From</span>
            <span className="questions-count">{quizData.n_section}</span>
          </>
        )}
      </div>

      {!isParagraphe && (
        <div className="control-buttons flex justify-center gap-[20px]">
          <button
            onClick={() =>
              dispatch({
                type: "setCurrentSectionIndex",
                payload: state.currentSectionIndex - 1,
              })
            }
            disabled={state.currentSectionIndex === 1 ? true : false}
            className="prev-btn flex w-[96px] justify-center rounded-md border-2 border-light py-[4px] text-center font-bold  text-light duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
          >
            <span className="md:hidden">Previous</span>
            <FontAwesomeIcon
              className="hidden rotate-180 text-[20px] font-bold md:block"
              icon={faChevronRight}
            />
          </button>

          <button
            onClick={() =>
              dispatch({
                type: "setCurrentSectionIndex",
                payload: state.currentSectionIndex + 1,
              })
            }
            disabled={
              state.currentSectionIndex === state.questions.length
                ? true
                : false
            }
            className="next-btn flex w-[96px] justify-center rounded-md border-2 border-light bg-light py-[4px]  text-center font-bold text-secondary duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
          >
            <span className="md:hidden">Next</span>

            <FontAwesomeIcon
              className="hidden text-[20px] font-bold md:block"
              icon={faChevronRight}
            />
          </button>
        </div>
      )}

      {isParagraphe && (
        <div className="control-buttons flex justify-center gap-[20px]">
          <button
            onClick={() =>
              dispatch({
                type: "setCurrentSectionIndex",
                payload: state.currentSectionIndex - 1,
              })
            }
            disabled={state.currentSectionIndex === 1 ? true : false}
            className="prev-btn flex w-[96px] justify-center rounded-md border-2 border-light py-[4px] text-center font-bold  text-light duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
          >
            <span className="md:hidden">Previous</span>
            <FontAwesomeIcon
              className="hidden rotate-180 text-[20px] font-bold md:block"
              icon={faChevronRight}
            />
          </button>

          <button
            onClick={() =>
              dispatch({
                type: "setCurrentSectionIndex",
                payload: state.currentSectionIndex + 1,
              })
            }
            disabled={
              state.currentSectionIndex === quizData.n_section ? true : false
            }
            className="next-btn flex w-[96px] justify-center rounded-md border-2 border-light bg-light py-[4px]  text-center font-bold text-secondary duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
          >
            <span className="md:hidden">Next</span>

            <FontAwesomeIcon
              className="hidden text-[20px] font-bold md:block"
              icon={faChevronRight}
            />
          </button>
        </div>
      )}

      <Popconfirm
        okText="Confirm"
        okType="danger"
        cancelText="Cancel"
        color="#fecaca"
        //okButtonProps={{ loading: loading }}
        onConfirm={onFinishQuiz}
        title="Do you really want to Submit?"
      >
        <button
          // disabled={
          //   state.questions.length === state.answeredQuestions.length
          //     ? false
          //     : true
          // }
          className="submit-btn rounded-md border-light bg-accent px-[16px]  py-[8px]  font-bold text-light duration-200 hover:scale-110 active:scale-90 "
        >
          Submit
        </button>
      </Popconfirm>
    </footer>
  );
};

export default QuizFooter;
