import React from "react";

export const DisplayQuestion = ({ state, children }) => {
  return (
    <div
      className={`left-question-container questions-section flex min-h-full w-[90%] flex-col  items-center py-[40px] md:w-full ${
        state.response && "hidden"
      }`}
    >
      {children}
    </div>
  );
};
