import React, { useEffect, useState } from "react";
import logoLight from "../../../assets/logo-lightwhite.png";
import logoDark from "../../../assets/logo-light.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  faFacebook,
  faTiktok,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const LandingNavbar = () => {
  const [showMobileMenue, setShowMobileMenue] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {" "}
      <div
        className={` foliox_tm_mobile_menu w-full h-auto fixed top-0 left-0 hidden z-[10]`}
      >
        <div
          className={`${
            scrolling ? "bg-[#4472c4] text-white" : "bg-[#fff] text-[#4472c4]"
          } mobile_menu_inner w-full h-auto clear-both float-left  border-solid border-[rgba(255,255,255,.1)] border py-[10px] px-[20px]`}
        >
          <div className="mobile_in w-full h-auto clear-both float-left flex items-center justify-between">
            <Link to="/">
              <div className="logo">
                {scrolling ? (
                  <img
                    className="max-w-[120px] max-h-[70px]"
                    src={logoLight}
                    alt=""
                  />
                ) : (
                  <img
                    className="max-w-[120px] max-h-[70px]"
                    src={logoDark}
                    alt=""
                  />
                )}
              </div>
            </Link>
            <div className="trigger leading-[0]">
              <div
                className="hamburger hamburger--slider text-3xl"
                onClick={() => setShowMobileMenue((e) => !e)}
              >
                <FontAwesomeIcon icon={faBars} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`dropdown w-full h-auto clear-both float-left bg-[#4472c4] ${
            showMobileMenue ? "" : "hidden"
          }`}
        >
          <div className="dropdown_inner w-full h-auto clear-both float-left py-[25px] px-[40px]">
            <ul className="anchor_nav">
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/pre-courses"
                >
                  Prep Courses
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/est-materials"
                >
                  EST
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/act-materials"
                >
                  ACT
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/dsat-materials"
                >
                  DSAT
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/homeschooling"
                >
                  Homeschooling Admission
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/signin"
                >
                  Signin
                </Link>
              </li>
              <li className="float-left w-full">
                <Link
                  className="text-white font-poppins transition-all duration-300 hover:text-extra-color"
                  to="/register"
                >
                  Signup
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`${
          scrolling ? "bg-[#4472c4] text-white" : "bg-[#fff] text-[#4472c4]"
        } foliox_tm_header fixed top-0 left-0 right-0 z-[50] py-[5px] px-0 transition-all duration-300`}
      >
        <div className="container">
          <div className="inner w-full flex items-center justify-between">
            <Link to="/">
              <div className="logo">
                {scrolling ? (
                  <img
                    className="max-w-[120px] max-h-[70px]"
                    src={logoLight}
                    alt=""
                  />
                ) : (
                  <img
                    className="max-w-[120px] max-h-[70px]"
                    src={logoDark}
                    alt=""
                  />
                )}
              </div>
            </Link>
            <div className="details flex items-center">
              <div className="menu">
                <ul className="anchor_nav flex items-center">
                  <li className="mr-[10px]">
                    <Link className=" font-poppins  " to="/pre-courses">
                      Prep Courses
                    </Link>
                  </li>
                  <li className="mr-[10px]">
                    <Link className="font-poppins" to="/est-materials">
                      EST
                    </Link>
                  </li>
                  <li className="mr-[10px]">
                    <Link className="font-poppins" to="/act-materials">
                      ACT
                    </Link>
                  </li>
                  <li className="mr-[10px]">
                    <Link className="font-poppins" to="/dsat-materials">
                      DSAT
                    </Link>
                  </li>
                  <li className="mr-[10px]">
                    <Link className="font-poppins" to="/homeschooling">
                      Homeschooling Admission
                    </Link>
                  </li>
                  <li>
                    <Link className="font-poppins" to="/signin">
                      Signin
                    </Link>
                  </li>
                  <li>
                    <Link className="font-poppins" to="/register">
                      Signup
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="social relative ml-[60px]">
                <ul className="flex items-center">
                  <li className="mr-[12px]">
                    <a className="" href="https://www.facebook.com/SATESTACT">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li className="mr-[12px]">
                    <a
                      className=""
                      href="https://www.youtube.com/@nashaatelgezeery1246"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li className="mr-[12px]">
                    <a
                      className=""
                      href="https://www.tiktok.com/@nashaat_elgezeery?_t=8m84wL2yYcg&_r=1"
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </a>
                  </li>
                  <li className="mr-[12px]">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className=""
                      href="https://api.whatsapp.com/send?phone=01092363507"
                    >
                      <FontAwesomeIcon className="" icon={faWhatsapp} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingNavbar;
