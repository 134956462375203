import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AdminViewExam = ({ addStudent }) => {
  const { examID, courseID } = useParams();
  const tableType = addStudent ? "viewStudentCourse" : "viewStudentExam";
  const table = useTable(tableType, "", "", "", "", examID || courseID);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Exams | Mr.Ahmed Rashsad"></HelmetTags>
      <h2 className="mb-10 w-full  text-4xl font-bold md:text-center">
        All Students
      </h2>

      {addStudent && (
        <Link
          to={`/admin/courses/all-students/${courseID}/add-student`}
          className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Student
        </Link>
      )}

      <div className="h-fit  w-full mt-10">{table}</div>
    </section>
  );
};

export default AdminViewExam;
