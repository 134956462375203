import { Modal, Radio } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import toast from "react-hot-toast";

const AdminTakeExamCopyModal = ({ children, examId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCourseId();
    setSessionId();
  };

  const [GetMatrialsSuccess, GetMatrialsErrors, GetMatrialsLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_COURSES_AND_SESSIONS_API}`,
    "GET",
    "GET",
    ""
  );

  const [courseId, setCourseId] = useState();
  const [sessionId, setSessionId] = useState();

  const onChange = (e) => {
    setCourseId(e.target.value);
    setSessionId();
  };

  const onChangeSessionId = (e) => {
    setSessionId(e.target.value);
  };

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMatrial": {
        return {
          ...state,
          submitAddEditMatrial: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditMatrial: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!--------- add edit Video -------

  const [MatrialAddEditSuccess, MatrialAddEditErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_COPY_COURSES_AND_SESSIONS_API,
    "POST",
    state.submitAddEditMatrial.flag,
    state.submitAddEditMatrial.dependency,
    state.submitAddEditMatrial.data,
    true
  );

  const onSubmit = () => {
    if (!courseId || !sessionId) {
      toast.error("Please Select Course And Session");
      return;
    }
    const finalData = {
      session_id: sessionId,
      exam_id: examId,
    };
    dispatch({
      type: "setSubmitAddEditMatrial",
      payload: {
        flag: "AddEditMatrial",
        dependency: !state.submitAddEditMatrial.dependency,
        data: finalData,
      },
    });
  };

  useEffect(() => {
    if (MatrialAddEditSuccess) {
      handleCancel();
    }
  }, [MatrialAddEditSuccess]);
  return (
    <>
      <button onClick={showModal}>{children}</button>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="examCopyModal"
        width={"70%"}
      >
        <h2 className="font-bold text-3xl mt-10 mb-5">All Courses</h2>
        <div className="flex flex-col">
          <Radio.Group
            onChange={onChange}
            value={courseId}
            className=" flex flex-col gap-5"
          >
            {GetMatrialsSuccess?.data?.map((martrial) => (
              <>
                <Radio
                  value={martrial.id}
                  key={martrial.id}
                  className="font-bold"
                >
                  {martrial.name}
                </Radio>
                {martrial.id === courseId && (
                  <Radio.Group
                    onChange={onChangeSessionId}
                    value={sessionId}
                    className=" flex flex-col gap-5 ml-10"
                  >
                    {martrial.sessions.map((session) => (
                      <Radio value={session.id} key={session.id}>
                        {session.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              </>
            ))}
          </Radio.Group>
        </div>
        <div className="flex items-center justify-end gap-5 mt-10">
          <button
            onClick={handleCancel}
            className="w-[100px] rounded-2xl border-2 border-accent bg-accent p-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
          >
            Cancel
          </button>
          <button
            onClick={onSubmit}
            className="w-[100px] rounded-2xl border-2 border-secondary bg-secondary p-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminTakeExamCopyModal;
