import { useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import CardS2 from "../../../../MainComponents/CardS2";

const AdminCourseDetails = () => {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const { courseID } = useParams();

  const [
    allCoursesDetailsData,
    allCoursesDetailsErrors,
    allCoursesDetailsloading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_COURSES_API}/${courseID}`,
    "GET",
    "GET",
    reFetch,
    "",
    ""
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title={`Course | Nash Academy`}></HelmetTags>

      <div className="mb-32 flex flex-col gap-10 ">
        <h2 className="w-full text-4xl font-bold md:text-center">
          {allCoursesDetailsData?.course_name}
        </h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("exam_add")
          )) && (
          <div className="flex w-full md:justify-evenly">
            <Link
              to={`/admin/courses/${courseID}/add-session`}
              className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Session
            </Link>
          </div>
        )}
        {allCoursesDetailsErrors && (
          <p className="font-bold text-3xl text-center mt-8">
            {allCoursesDetailsErrors}
          </p>
        )}
        {allCoursesDetailsloading && !allCoursesDetailsErrors ? (
          <Loader />
        ) : (
          <div className="lectures grid-auto-fit w-full mt-6">
            {allCoursesDetailsData?.data?.map((data) => (
              <CardS2 data={data} setReFetch={setReFetch} admin />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default AdminCourseDetails;
