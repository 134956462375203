import React from "react";

const LandingFooter = () => {
  return (
    <div className="foliox_tm_section w-full h-auto clear-both float-left">
      <div className="foliox_tm_copyright w-full h-auto clear-both float-left text-center py-[50px] px-0">
        <div className="container">
          <p className="wow fadeInUp font-poppins" data-wow-duration="1s">
            Created By{" "}
            <a
              className="font-bold transition-all duration-300 hover:text-extra-color"
              href="https://api.whatsapp.com/send?phone=201124265941"
              target="_blank"
              rel="noreferrer"
            >
              Omar Fathy
            </a>{" "}
            | &copy; 2024 All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
