import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import VideoCard from "../../../../MainComponents/VideoCard";

const AdminCourseSession = () => {
  const AuthState = useAuthState();
  const { courseID, sessionID } = useParams();

  const [reFetch, setReFetch] = useState(false);
  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  //!---- get all Matrials -------
  const [GetMatrialsSuccess, GetMatrialsErrors, GetMatrialsLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_INFO_COURSE_SESSION_MATERIAL_API}/${sessionID}`,
    "GET",
    sessionID,
    reFetch || sessionID
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Sessions | Nash Academy"></HelmetTags>

      <div
        onClick={() => setShowVideo((prev) => ({ ...prev, show: false }))}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
          showVideo.show === true
            ? "opacity- pointer-events-auto scale-100"
            : "opacity- pointer-events-none scale-0"
        } `}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
          <div
            className="flex items-center justify-center h-full"
            dangerouslySetInnerHTML={{
              __html: showVideo.src,
            }}
          />
          {/* <iframe
            src={showVideo.src}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameBorder="0"
            //style={{ aspectRatio: "16 / 9" }}
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe> */}
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          <Link
            to={`/admin/courses/${courseID}/session/${sessionID}/add-video`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Video
          </Link>

          <Link
            to={`/admin/courses/${courseID}/session/${sessionID}/add-pdf`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Pdf
          </Link>

          <Link
            to={`/admin/courses/${courseID}/session/${sessionID}/add-link`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Link
          </Link>

          <Link
            to={`/admin/courses/${courseID}/session/${sessionID}/add-exam`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Exam
          </Link>
          <Link
            to={`/admin/courses/${courseID}/session/${sessionID}/add-quiz`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Quiz
          </Link>
        </div>
        <div className="videos video-grid-auto-fit w-full ">
          {GetMatrialsSuccess?.data?.length === 0 ? (
            <p>لا يوجد فيديوهات لهذة المحاضرة</p>
          ) : (
            GetMatrialsSuccess?.data?.map((video) => (
              <VideoCard
                reFetch={reFetch}
                setReFetch={setReFetch}
                setShowVideo={setShowVideo}
                admin
                video={video}
                key={video.key}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default AdminCourseSession;
