import {
  faFileAlt,
  faFilePdf,
  faPenClip,
  faPlus,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import VideoCard from "../../../../MainComponents/VideoCard";
import { Loader } from "../../../../MainComponents";

const AdminMaterials = ({ type, reading }) => {
  const AuthState = useAuthState();
  const { matrialType } = useParams();

  const [reFetch, setReFetch] = useState(false);
  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  const pathReading = reading ? "reading" : "writing";

  //!---- get all Matrials -------
  const [GetMatrialsSuccess, GetMatrialsErrors, GetMatrialsLoading] = useAxios(
    `${process.env.REACT_APP_GET_ALL_MATRIALS_API}/${type}/${pathReading}/${matrialType}`,
    "GET",
    type,
    reFetch || type
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          type === "est"
            ? "EST Materials"
            : type === "act"
              ? "ACT Materails"
              : type === "dsat"
                ? "DSAT Materials"
                : "Homeschooling Admission"
        } | Nash Academy`}
      ></HelmetTags>

      <div
        onClick={() => setShowVideo((prev) => ({ ...prev, show: false }))}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
          showVideo.show === true
            ? "opacity- pointer-events-auto scale-100"
            : "opacity- pointer-events-none scale-0"
        } `}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary flex items-center justify-center">
          <div
            dangerouslySetInnerHTML={{
              __html: showVideo.src,
            }}
          ></div>
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 class="w-full text-4xl font-bold md:text-center">
          {type === "est"
            ? "EST Materials"
            : type === "act"
              ? "ACT Materails"
              : type === "dsat"
                ? "DSAT Materials"
                : "Homeschooling Admission"}
        </h2>
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {matrialType === "videos" &&
            (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Link
                to={
                  type === "est"
                    ? `/admin/est-materials/materials${pathReading}/add-video`
                    : type === "act"
                      ? `/admin/act-materials/materials${pathReading}/add-video`
                      : type === "dsat"
                        ? `/admin/dsat-materials/materials${pathReading}/add-video`
                        : ``
                }
                className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add Video
              </Link>
            )}
          {matrialType === "pdfs" && (
            <div className="top-btns flex w-full justify-start gap-5 asm:flex-col asm:items-center ">
              <div className="w-1/3 md:w-full">
                <Link
                  to={
                    type === "est"
                      ? `/admin/est-materials/materials/${pathReading}/pdfs/tests`
                      : type === "act"
                        ? `/admin/act-materials/materials/${pathReading}/pdfs/tests`
                        : type === "dsat"
                          ? `/admin/dsat-materials/materials/${pathReading}/pdfs/tests`
                          : null
                  }
                  class="service-card  md:w-full shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
                >
                  <FontAwesomeIcon icon={faPenClip} />

                  <p class="font-bold text-3xl group-hover:text-white text-secondary">
                    Tests
                  </p>
                </Link>
              </div>
              <div className="w-1/3 md:w-full">
                <Link
                  to={
                    type === "est"
                      ? `/admin/est-materials/materials/${pathReading}/pdfs/books`
                      : type === "act"
                        ? `/admin/act-materials/materials/${pathReading}/pdfs/books`
                        : type === "dsat"
                          ? `/admin/dsat-materials/materials/${pathReading}/pdfs/books`
                          : null
                  }
                  class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary  hover:text-white"
                >
                  <FontAwesomeIcon icon={faFilePdf} />

                  <p class="font-bold text-3xl group-hover:text-white text-secondary">
                    Books
                  </p>
                </Link>
              </div>
              <div className="w-1/3">
                <Link
                  to={
                    type === "est"
                      ? `/admin/est-materials/materials/${pathReading}/pdfs/topics`
                      : type === "act"
                        ? `/admin/act-materials/materials/${pathReading}/pdfs/topics`
                        : type === "dsat"
                          ? `/admin/dsat-materials/materials/${pathReading}/pdfs/topics`
                          : null
                  }
                  class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
                >
                  <FontAwesomeIcon icon={faFileAlt} />

                  <p class="font-bold text-3xl group-hover:text-white text-secondary">
                    Topics
                  </p>
                </Link>
              </div>
            </div>
          )}
          {matrialType === "practice" &&
            (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Link
                to={
                  type === "est"
                    ? "/admin/est-materials/practices"
                    : type === "act"
                      ? "/admin/act-materials/practices"
                      : type === "dsat"
                        ? "/admin/dsat-materials/practices"
                        : "/admin/homeschooling/practices"
                }
                className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
              >
                {/* <FontAwesomeIcon className="mr-4" icon={faPlus} /> */}
                Online Practices
              </Link>
            )}
        </div>

        {matrialType !== "pdfs" && (
          <div className="videos video-grid-auto-fit w-full ">
            {GetMatrialsLoading ? (
              <Loader />
            ) : GetMatrialsSuccess?.data?.length === 0 ? (
              <p>There is no Materails</p>
            ) : (
              GetMatrialsSuccess?.data?.map((video) => (
                <VideoCard
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  setShowVideo={setShowVideo}
                  admin
                  video={video}
                  type={type}
                  key={video.key}
                  pathReading={pathReading}
                />
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default AdminMaterials;
