import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizTimer from "./QuizTimer";

const QuizHeader = ({
  state,
  dispatch,
  quizErrors,
  onFinishQuiz,
  quizLoading,
  exam,
}) => {
  const { ID } = useParams();

  return (
    <header
      className={`w-full h-[80px] sticky inset-0 z-50 flex items-center bg-secondary text-light shadow-md shadow-secondary/40`}
    >
      <nav
        className={`nav-container width flex h-fit items-center gap-[20px] justify-between`}
      >
        <div className="header-above   w-fit">
          <h4 className="exam-title font-bold">{state?.quizInfo?.title}</h4>
          {state.isShowAnswers ||
            (state.quizInfo?.duration && (
              <QuizTimer
                duration={state.quizInfo.duration}
                state={state}
                onFinishQuiz={onFinishQuiz}
              />
            ))}
        </div>

        {state.isShowAnswers && (
          <Link
            to={`${
              exam ? `/home/exams/${ID}` : `/home/courses/sessions/${ID}`
            }`}
          >
            <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
              <FontAwesomeIcon
                className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                  state.collapse && "rotate-180"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
        )}
        <div
          className={`header-underneath  flex items-center justify-between gap-[20px] ${
            state.isShowAnswers && "pointer-events-none hidden"
          }`}
        >
          <div className="flex justify-end md:w-fit">
            {!quizErrors && !quizLoading && (
              <div
                onClick={() => dispatch({ type: "setCollapse" })}
                className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md  shadow-black/40 duration-300 hover:scale-110 hover:text-light"
              >
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                    state.collapse && "rotate-180"
                  }`}
                  icon={faChevronRight}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default QuizHeader;
