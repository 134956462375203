import { Doughnut } from "react-chartjs-2";

const PercentageSidebar = ({ state }) => {
  const percentageInfo = state.finishQuizData;
  const DoughnutData = {
    labels: ["Wrong answers", "Correct answers"],
    datasets: [
      {
        label: "Model answers",
        data: [percentageInfo?.count_wrong, percentageInfo?.count_correct],
        backgroundColor: ["rgb(199, 31, 56)", "rgb(132, 204, 22)"],
        borderColor: ["rgba(199, 31, 56, 1)", "rgb(132, 204, 22,1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      className={`left-right-sidebar text- flex min-h-full w-1/4 flex-col items-center  border-logoYellow border-2 px-[20px] py-[40px] md:-order-1 md:min-h-fit md:w-full`}
    >
      <p className="overall w-full text-center text-[20px] font-bold">
        Total success rate
      </p>
      <div className="DoughnutData my-[36px] flex  w-full  flex-col items-center md:w-4/6 xs:w-5/6">
        <Doughnut data={DoughnutData} />
      </div>

      <p className="absolute-text mb-[20px] text-center text-[18px] font-bold  text-lime-500">
        Correct Answer <br />
        <span className="text-[18px]  font-normal">
          {percentageInfo?.percentage}%
        </span>
      </p>
      <p className="grade">
        <span className="font-bold">Degree:</span>
        {percentageInfo?.student_degree}/{percentageInfo?.degree}
      </p>
    </div>
  );
};

export default PercentageSidebar;
