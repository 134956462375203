import { useEffect, useRef, useState } from "react";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useFade from "../../../MainComponents/Hooks/useFade";
import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../MainComponents";
import { Col, Row } from "antd";

const StudentQuestionBank = () => {
  const [questions, setQuestions] = useState([]);
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  const [questionsBankData, questionsBankErrors, questionsBankLoading] =
    useAxios(
      process.env.REACT_APP_GET_QUESTION_BANCK_STUDNET_API,
      "GET",
      "GET"
    );

  useEffect(() => {
    if (questionsBankData?.data) setQuestions(questionsBankData?.data);
  }, [questionsBankData]);

  if (questionsBankLoading) {
    return <Loader />;
  }
  if (questionsBankErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>There are no questions currently</p>
      </div>
    );
  }

  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light ">
      <HelmetTags title="Question Bank | Nash Academy"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear text-accent ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          Question Bank
        </h2>
      </div>
      <div className="">
        <Row gutter={[20, 20]}>
          {questions.map((question, i) => (
            <Col xs={24} md={24} lg={8} key={i}>
              <div className=" w-full flex items-center justify-between border-2 border-secondary p-6 rounded-xl ">
                <p className="font-bold">{question.name}</p>
                <Link
                  to={`/home/question-bank/${question.key}`}
                  className="bg-secondary text-white p-2 px-7 rounded-xl border-2 border-secondary hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary "
                >
                  View
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default StudentQuestionBank;
