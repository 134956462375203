import { useRef } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";
import useAxios from "../../../MainComponents/Hooks/useAxios";

function Exams() {
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  //!---- get all Exams  -------

  const [allExamsData, allExamsErrors, allExamsLoading] = useAxios(
    process.env.REACT_APP_STUDENT_TRIAL_EXAMS_STUDENT_TAB_API,
    "GET",
    "GET"
  );

  if (allExamsLoading) {
    return <Loader />;
  }

  if (allExamsErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allExamsErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light ">
      <HelmetTags title="Exams | Nash Academy "></HelmetTags>{" "}
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          All Exams
        </h2>
      </div>
      <div className="lectures grid-auto-fit">
        {allExamsData?.data?.length === 0 && <p>No Exams Yet!</p>}
        {allExamsData?.data.map((course) => (
          <SingleCourse exam lecture={course} key={course.id} />
        ))}
      </div>
    </section>
  );
}

export default Exams;
