import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faCirclePlay,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import VideoImage from "../assets/video-preview.jpg";
import PdfImage from "../assets/pdf-preview.png";
import LinkImage from "../assets/link-preview.svg";
import ExamImage from "../assets/exam.jpg";
import AdminTakeExamCopyModal from "../Pages/Admin/Dashboard/AdminCourses/AdminTakeExamCopyModal.jsx";

function VideoCard({
  video,
  admin,
  reFetch,
  setReFetch,
  setShowVideo,
  type,
  pathReading,
  landing,
  past,
}) {
  const AuthState = useAuthState();
  const { courseID, sessionID } = useParams();
  const navigate = useNavigate();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Video -------

  const apiDelete =
    video.type === "quiz" || video.type === "exam"
      ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
      : process.env.REACT_APP_ADMIN_COURSE_SESSION_DELETE_MATERIAL_API;

  const [deleteVideoSuccess, deleteVideoErrors, deleteloading] = useAxios(
    apiDelete,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteVideo(id) {
    setSubmitDelete({
      flag: "deleteVideo",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteVideoSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteVideoSuccess]);

  // booster image vidoe from iframge
  const getYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  };

  const videoID = getYouTubeVideoID(video.link);
  const posterUrl = videoID
    ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
    : "";

  let src = video.link;

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course flex h-[440px] w-[400px]  flex-col justify-between rounded-[25px] border-2 border-secondary bg-white p-8 shadow-lg sm:h-[375px] sm:w-[300px]"
    >
      <div className="video relative h-[250px] w-full cursor-pointer overflow-hidden rounded-[20px]">
        <div className="video-wrapper h-full w-full flex items-center justify-center  ">
          <img
            onClick={() => {
              if (landing) {
                navigate("/signin");
              } else if (video.type === "videos") {
                if (video.is_free === 1) {
                  setShowVideo({
                    show: true,
                    src: src,
                  });
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }}
            className="h-full object-cover"
            src={
              video.type === "pdfs"
                ? PdfImage
                : video.type === "link"
                  ? LinkImage
                  : video.type === "quizzes" || video.type === "exams"
                    ? video.img
                    : video.type === "quiz" || video.type === "exam"
                      ? ExamImage
                      : videoID
                        ? posterUrl
                        : VideoImage
            }
            alt={video.name}
          />
        </div>

        {video.type === "videos" ? (
          <FontAwesomeIcon
            className="pointer-events-none absolute  bottom-1/2 right-1/2 z-10 h-20 w-20 translate-x-1/2 translate-y-1/2 text-light duration-300 hover:scale-110 active:scale-90"
            icon={video.is_free === 1 ? faCirclePlay : faLock}
          />
        ) : null}

        {video.type === "pdfs" ||
        video.type === "quizzes" ||
        video.type === "exams" ? (
          <FontAwesomeIcon
            className="pointer-events-none absolute  bottom-1/2 right-1/2 z-10 h-20 w-20 translate-x-1/2 translate-y-1/2 text-light duration-300 hover:scale-110 active:scale-90"
            icon={video.is_free === 0 ? faLock : null}
          />
        ) : null}

        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("")
          )) && (
          <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold capitalize">
            {video.type}
          </div>
        )}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("")
          )) && (
          <div className="creator pointer-events-none absolute left-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold capitalize">
            {video.is_free ? "Free" : "Paid"}
          </div>
        )}
      </div>
      <div className="course-details  hf mt-7 flex flex-col justify-between">
        <h3
          className={`title mb-7 w-fit cursor-pointer text-secondary text-2xl  font-bold`}
        >
          {video.type === "quiz" || video.type === "exam" ? (
            <Link
              to={`/admin/courses/${courseID}/session/${sessionID}/${video.type}/${video.key}`}
            >
              {video?.name?.substring(0, 33)}
            </Link>
          ) : (
            <>{video?.name?.substring(0, 33)}</>
          )}
        </h3>

        <div
          className={`visibility-cta bg-red-0 mt-5 flex w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          } items-center pt-8`}
        >
          {admin ? (
            <>
              {video.type === "quiz" || video.type === "exam" ? (
                <AdminTakeExamCopyModal examId={video.key}>
                  <div className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Copy
                  </div>
                </AdminTakeExamCopyModal>
              ) : null}

              <div className="admin-options  flex w-full justify-end gap-6">
                {video.type === "quiz" || video.type === "exam" ? (
                  <Link
                    to={`/admin/courses/${courseID}/session/${sessionID}/${video.key}`}
                    className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    Students
                  </Link>
                ) : null}
                <Link
                  to={
                    type && !past
                      ? `/admin/${type}-materials/materials/${pathReading}/edit-${video.type}/${video.key}`
                      : past
                        ? `edit-pdf/${video.key}`
                        : `/admin/courses/${courseID}/session/${video.key}/edit-${video.type}`
                  }
                  className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                >
                  Edit
                </Link>

                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteVideo(video.key)}
                  title="Do you really want to delete?"
                >
                  <button className="w-24 rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              </div>
            </>
          ) : (
            <>
              {video.type === "quizzes" || video.type === "exams" ? (
                <button
                  disabled={video.is_free === 0}
                  onClick={() =>
                    navigate(
                      landing
                        ? "/signin"
                        : `/home/${type}-materials/practise/${video.key}`
                    )
                  }
                  className="w-1/2 cta flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-secondary   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90"
                >
                  Practise
                </button>
              ) : video.type === "videos" ? (
                <button
                  onClick={() => {
                    if (landing) {
                      navigate("/signin");
                    } else if (video.type === "videos") {
                      if (video.is_free === 1) {
                        setShowVideo({
                          show: true,
                          src: src,
                        });
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  }}
                  className="w-1/2 cta flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-secondary   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90"
                >
                  Watch
                </button>
              ) : (
                <>
                  {video.is_free === 0 ? (
                    <button
                      disabled={landing ? false : video.is_free === 0}
                      onClick={() => navigate("/signin")}
                      className="w-1/2 cta flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-secondary   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90"
                    >
                      Download
                    </button>
                  ) : (
                    <a
                      href={video.link}
                      target="_blank"
                      className="w-1/2 cta flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-secondary   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default VideoCard;
