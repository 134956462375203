import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faFileAlt, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminMaterialsStarter({ section }) {
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title={`materials | Nash Academy `}></HelmetTags>

      <div className="mb-20 flex flex-col items-start gap-20 ">
        <h2 className="w-full text-4xl font-bold">
          {section?.toUpperCase()} Materials
        </h2>

        <div className="top-btns flex w-full justify-start gap-5 asm:flex-col asm:items-center ">
          <div className="w-1/2 md:w-full">
            <Link
              to={`materials`}
              class="service-card  md:w-full shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
            >
              <FontAwesomeIcon icon={faFileAlt} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Materials
              </p>
            </Link>
          </div>

          <div className="w-1/2 md:w-full">
            <Link
              to={"past-paper"}
              class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
            >
              <FontAwesomeIcon icon={faPenAlt} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Past paper
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminMaterialsStarter;
