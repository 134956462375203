import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import VideoCard from "../../../../MainComponents/VideoCard";
import { Loader } from "../../../../MainComponents";

const AdminMaterialsPdf = ({ type, reading, tests, topics, books, past }) => {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

  const pathReading = reading ? "reading" : "writing";

  //!---- get all Matrials -------
  const api = past
    ? `${process.env.REACT_APP_GET_ALL_PAST_MATRIALS_API}/${type}`
    : `${process.env.REACT_APP_GET_ALL_MATRIALS_API}/${type}/${pathReading}/pdfs`;

  const [GetMatrialsSuccess, GetMatrialsErrors, GetMatrialsLoading] = useAxios(
    api,
    "GET",
    type,
    reFetch || type
  );

  const filteredData = GetMatrialsSuccess?.data?.filter((card) => {
    if (tests) {
      return card.pdftype === "tests";
    }

    if (topics) {
      return card.pdftype === "topics";
    }

    if (books) {
      return card.pdftype === "books";
    }

    if (past) {
      return card;
    }
    return null;
  });

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${
          type === "est"
            ? "EST Materials"
            : type === "act"
              ? "ACT Materails"
              : type === "dsat"
                ? "DSAT Materials"
                : "Homeschooling Admission"
        } | Nash Academy`}
      ></HelmetTags>

      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 class="w-full text-4xl font-bold md:text-center">
          {type === "est"
            ? "EST Materials"
            : type === "act"
              ? "ACT Materails"
              : type === "dsat"
                ? "DSAT Materials"
                : "Homeschooling Admission"}
        </h2>
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("")
            )) && (
            <Link
              to={
                type === "est"
                  ? `add-pdf`
                  : type === "act"
                    ? `add-pdf`
                    : type === "dsat"
                      ? `add-pdf`
                      : ``
              }
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Pdf
            </Link>
          )}
        </div>

        <div className="videos video-grid-auto-fit w-full ">
          {GetMatrialsLoading ? (
            <Loader />
          ) : filteredData?.length === 0 ? (
            <p>There is no Materails</p>
          ) : (
            filteredData?.map((video) => (
              <VideoCard
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                video={video}
                type={type}
                key={video.key}
                past={past}
                pathReading={pathReading}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default AdminMaterialsPdf;
