import { Outlet } from "react-router-dom";
import LandingNavbar from "../Pages/LandingPage/Components/LandingNavbar";
import LandingFooter from "../Pages/LandingPage/Components/LandingFooter";

// landing page style
// import "../SASS/plugins.scss";

function LandingLayout() {
  return (
    <>
      <LandingNavbar />
      <div className="min-h-screen h-full pt-28">
        <Outlet />
      </div>
      <LandingFooter />
    </>
  );
}

export default LandingLayout;
