import { useEffect } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { useForm } from "react-hook-form";
import { useReducer } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const UpdateAdminImage = ({ values }) => {
  const AuthState = useAuthState();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const [EditPhotoSuccess, EditPhotoErrors, PhotoLoading] = useAxios(
    process.env.REACT_APP_ADMIN_CHANGE_PROFILE_PHOTO_API,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (EditPhotoSuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
    }
  }, [EditPhotoSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    values,
  });

  useEffect(() => {
    const subscription = watch((input) => {
      if (input.profile_pic[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.profile_pic[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { profile_pic: data.profile_pic[0] },
      },
    });
  };
  return (
    <section className="h-auto width flex flex-col items-center mt-6">
      <h4 className="text-3xl font-bold">Change Image</h4>
      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-blue-200 p-10 shadow-lg shadow-blue/50 my-6"
      >
        {/**  Profile Picture  */}
        <div className="w-full flex flex-col items-center">
          <div className="w-fit ">
            <label htmlFor="profile_pic">
              {" "}
              <div className="profile_pic h-28 aspect-square overflow-hidden rounded-full border-2 border-secondary p-1 cursor-pointer">
                <img
                  className="h-full w-full rounded-full object-cover object-top"
                  src={
                    state.uploadedImgSrc ||
                    `https://api.nash-academy.com/uploads/profile_picture/${AuthState.userData?.admin?.profile_pic}`
                  }
                  alt="Admin Avatar"
                />
              </div>{" "}
            </label>
          </div>
          <input
            id="profile_pic"
            hidden
            className="signin-inputs  w-full"
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="profile_pic"
            {...register("profile_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.profile_pic && (
            <p className="pt-4 text-xl text-red-500">
              {errors.profile_pic.type === "required" &&
                "This field is required."}
              {errors.profile_pic.type === "validate" &&
                "Max Image Size is 5MB."}
            </p>
          )}
          {
            //!-------Editing server errors -----

            EditPhotoErrors?.response?.data?.errors &&
              EditPhotoErrors?.response?.data?.errors?.profile_pic && (
                <p className="pt-4 text-xl text-red-500">
                  {EditPhotoErrors?.response?.data.errors.profile_pic[0]}
                </p>
              )
          }
        </div>

        {/** Submit Button */}

        <button
          disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitEditPhoto.flag && PhotoLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Edit Photo"
          )}
        </button>
      </form>
    </section>
  );
};

export default UpdateAdminImage;
