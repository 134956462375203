import { useEffect, useRef } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import { useAuthState } from "../../../MainComponents/GlobalContext";
import HelmetTags from "../../../MainComponents/HelmetTags";

function HomePage() {
  const allPostsWrapper = useRef(null);
  const AuthState = useAuthState();
  //!---get posts data request
  const [postsDataSuccess, postsErrors, postsDataLoading] = useAxios(
    process.env.REACT_APP_STUDENT_POSTS_API,
    "GET",
    "GET"
  );
  useEffect(() => {
    if (postsDataSuccess && allPostsWrapper) {
      displayPosts();
    }
  }, [postsDataSuccess, allPostsWrapper]);

  function displayPosts() {
    /* if (postsErrors && !postsDataSuccess) {
      document.querySelector(".singleQuestionWrapper")?.remove();
      return;
    } */
    postsDataSuccess?.data?.forEach((post) => {
      //!--create containers
      let singleQuestionWrapper = document.createElement("div");
      singleQuestionWrapper.classList.add("singleQuestionWrapper");
      //!-----------
      let optionsQuestionWrapper = document.createElement("div");
      optionsQuestionWrapper.classList.add("optionsPostWrapper");
      //!-----------

      //!----------- PostWrapper
      let PostWrapper = document.createElement("div");
      PostWrapper.classList.add("PostWrapper");
      let essayTitleWrapper = document.createElement("div");
      essayTitleWrapper.classList.add("essayTitleWrapper");
      let postContent = document.createElement("div");
      postContent.innerHTML = `${post?.content}`;
      //!--remove the first child sent by the text editor
      if (
        postContent.childNodes.length > 0 &&
        postContent.firstChild.nodeType === 1
      ) {
        postContent.removeChild(postContent.firstChild);
      }

      PostWrapper.append(essayTitleWrapper, postContent);
      //!-----------
      //!--append the options and the question to their parent div
      optionsQuestionWrapper.append(PostWrapper);

      //!--append the children to the parents
      singleQuestionWrapper.append(optionsQuestionWrapper);

      allPostsWrapper?.current?.appendChild(singleQuestionWrapper);
    });
  }

  if (postsDataLoading) {
    return <Loader />;
  }

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Homeschooling | Nash Academy"></HelmetTags>

      {postsErrors && typeof postsErrors === "string" ? (
        <div className="w-full text-center mt-10">
          <p className="w-full text-center">{postsErrors}</p>
        </div>
      ) : (
        <div
          ref={allPostsWrapper}
          className="all-questions flex h-auto w-full flex-col gap-14 px-10 amd:px-0 mt-10"
        ></div>
      )}
    </section>
  );
}

export default HomePage;
