import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link } from "react-router-dom";
import { faFilePdf, faFileShield } from "@fortawesome/free-solid-svg-icons";

function AdminMatrialPractisesQuizzesView({ section }) {
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title={`Online Practices | Nash Academy `}></HelmetTags>

      <div className="mb-20 flex flex-col items-start gap-20 ">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Online Practices
        </h2>
        <div className="top-btns flex w-full justify-start gap-5 asm:flex-col asm:items-center ">
          <div className="w-1/2 md:w-full">
            <Link
              to={`topics`}
              class="service-card  md:w-full shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary hover:text-white"
            >
              <FontAwesomeIcon icon={faFileShield} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Topics
              </p>
            </Link>
          </div>
          <div className="w-1/2 md:w-full">
            <Link
              to={`drills`}
              class="service-card shadow-xl cursor-pointer snap-start shrink-0 py-8 px-6 bg-white flex flex-col items-start gap-3 transition-all duration-300 group hover:bg-secondary  hover:text-white"
            >
              <FontAwesomeIcon icon={faFilePdf} />

              <p class="font-bold text-3xl group-hover:text-white text-secondary">
                Drills
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminMatrialPractisesQuizzesView;
