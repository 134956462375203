import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios";
import { Popconfirm } from "antd";
import { useAuthState } from "./GlobalContext";
import { Link, useParams } from "react-router-dom";

const CardS2 = ({ data, admin, reFetch, setReFetch, landing }) => {
  const AuthState = useAuthState();
  const { courseID } = useParams();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  //!--------- delete Lecture -------
  // const apiDelete = exam
  //   ? process.env.REACT_APP_ADMIN_DELETE_COURSE_API
  //   : process.env.REACT_APP_ADMIN_DELETE_LECTURE_API;

  const [deleteLectureSuccess, deleteLectureErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_COURSE_SESSION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  function handleDeleteLecture(id) {
    setSubmitDelete({
      flag: "deleteLecture",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }

  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteLectureSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteLectureSuccess]);

  return (
    <div class="min-h-[400px] w-[350px]  bg-gray-800 p-3 flex flex-col gap-1 justify-between rounded-br-3xl">
      <div>
        <div class="duration-500 contrast-50 bg-gradient-to-bl from-black via-orange-900 to-indigo-600  hover:contrast-100">
          <Link
            to={
              admin
                ? `/admin/courses/${courseID}/session/${data.key}`
                : `/home/courses/sessions/${courseID}/${data.key}`
            }
          >
            <img
              className="h-[240px] w-full object-cover"
              src={data?.img}
              alt={data?.title}
            />
          </Link>
        </div>

        <div class="flex flex-col mt-4">
          <Link
            to={
              admin
                ? `/admin/courses/${courseID}/session/${data.key}`
                : `/home/courses/sessions/${courseID}/${data.key}`
            }
          >
            <span class="text-3xl text-gray-50 font-bold">{data.title}</span>
          </Link>
          <p class="text-2xl text-gray-400">{data.description}</p>
        </div>
      </div>
      <div
        className={`visibility-cta mt-5 flex w-full items-center pb-4 pt-12`}
      >
        {admin ? (
          <>
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture")
              )) && (
              <div className="admin-options flex w-full justify-end gap-5">
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(``)
                  )) && (
                  <Link
                    to={`/admin/courses/${courseID}/edit-session/${data.key}`}
                    className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    Edit
                  </Link>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(``)
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: loading }}
                    onConfirm={() => handleDeleteLecture(data?.key)}
                    title="Do you really want to delete?"
                  >
                    <button className="w-24 rounded-2xl border-2  border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="flex w-full items-center justify-center">
            <Link
              to={
                landing
                  ? `/pre-courses/sessions/${courseID}/${data.key}`
                  : `/home/courses/sessions/${courseID}/${data.key}`
              }
              className="w-50 rounded-2xl border-2  border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            >
              View Session
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardS2;
