const RightQuestionsSidebar = ({ state, dispatch, quizData }) => {
  const staticNumber = quizData?.n_section;
  // Create an array of the desired length
  const allSections = Array.from(
    { length: staticNumber },
    (_, index) => index + 1
  );
  return (
    <div>
      <div
        //*?this div is a placeholder rather than the fixed div to take 1/4 of the width
        className={` sidebar-collapse-placeholder h-questions bg- transition-all  duration-300 ease-in-out md:hidden  ${
          state.collapse ? "w-1/4 " : "w-0 "
        } `}
      ></div>
      <div
        className={` right-questions-sidebar-collapse h-questions fixed right-0 top-[128px] overflow-y-auto bg-secondary px-[20px] transition-all duration-300 ease-in-out md:w-full ${
          state.collapse ? "w-1/4 translate-x-0" : " w-0 translate-x-full"
        }  `}
      >
        <div
          className={`myfixed mytoggle-fixed h-fit w-full ${
            state.collapse ? "" : "hidden"
          } `}
        >
          <div className="myquestions-container-relative h-fit w-full mt-12">
            <div className="myquestions-wrapper mytoggle-QuestionsWrapper h-fit w-full">
              <div className="myactual-questions-container h-fit  w-full">
                {allSections?.map((question, i) => (
                  <span
                    key={i}
                    className={`${
                      state.currentSectionIndex === i + 1
                        ? "selected-popup-question"
                        : "myquestion"
                    }`}
                    onClick={() =>
                      dispatch({
                        type: "setCurrentSectionIndex",
                        payload: i + 1,
                      })
                    }
                  >
                    {i + 1}
                  </span>
                ))}
              </div>

              {/* TODO: ADD RESULT CONTAINER */}
              <div
                // ref={resultContainer}
                className="result-container flex h-fit w-full flex-col items-center  gap-[16px]"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightQuestionsSidebar;
