import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";

const ViewStudent = () => {
  const [filterActive, setFilterActive] = useState(1);
  const { studentID } = useParams();
  const tableFor =
    filterActive === 1
      ? "purchaseHistory"
      : filterActive === 2
        ? "trialExam"
        : "";

  const table = useTable(tableFor, "", "", "", "", studentID);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Nash Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          View student{" "}
        </h2>
      </div>

      <div
        className={`filters flex items-center  h-12 relative mb-8 md-hero:flex-col transition-all duration-200 ease-in-out`}
      >
        <button
          onClick={() => setFilterActive(1)}
          className={`z-10 h-full w-1/4 text-2xl font-semibold rounded ${
            filterActive === 1 &&
            "rounded-md bg-secondary duration-300 text-light"
          }`}
        >
          Lectures
        </button>
        <button
          onClick={() => setFilterActive(2)}
          className={`z-10 h-full w-1/4 text-2xl font-semibold rounded ${
            filterActive === 2 &&
            "rounded-md bg-secondary duration-300 text-light"
          }`}
        >
          Exams
        </button>
      </div>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default ViewStudent;
