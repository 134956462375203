import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function AdminMatrialPractises({ section, exam, reading, topics, drills }) {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

  const pathReading = reading ? "reading" : "writing";
  const isExam = exam ? "exams" : "quizzes";

  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    `${process.env.REACT_APP_GET_ALL_PRACTISES_MATRIALS_API}/${section}/${pathReading}/${isExam}`,
    "GET",
    "GET",
    reFetch,
    "",
    "",
    section
  );

  const filteredData = allExamsData?.data?.filter((card) => {
    if (topics) {
      return card.quiz_type === "topics";
    }

    if (drills) {
      return card.quiz_type === "drills";
    }

    return card;
  });

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title={`Online Practices | Nash Academy `}></HelmetTags>

      <div className="mb-32 flex flex-col gap-10 ">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Online Practices
        </h2>
        <div className="flex w-full md:justify-evenly gap-4">
          {exam &&
            (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("exam_add")
              )) && (
              <Link
                to={`add-exam`}
                className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add Exam
              </Link>
            )}
          {!exam &&
            (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Link
                to={`add-quiz`}
                className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add Quiz
              </Link>
            )}
        </div>
        {/* {allExamsErrors && (
          <p className="font-bold text-3xl text-center mt-8">
            {allExamsErrors}
          </p>
        )} */}
        {allExamsloading && !allExamsErrors ? (
          <Loader />
        ) : (
          <div className="lectures grid-auto-fit w-full mt-6">
            {filteredData?.map((Card) => (
              <SingleCourse
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                exam
                lecture={Card}
                key={Card.key}
                section
                singleLink={Card.key}
                editLink={
                  section === "est"
                    ? `/admin/est-materials/practices/edit-${
                        Card.is_exam ? "exam" : "quiz"
                      }/${Card.key}`
                    : section === "act"
                      ? `/admin/act-materials/practices/edit-${
                          Card.is_exam ? "exam" : "quiz"
                        }/${Card.key}`
                      : section === "dsat"
                        ? `/admin/dsat-materials/practices/edit-${
                            Card.is_exam ? "exam" : "quiz"
                          }/${Card.key}`
                        : null
                }
                studentsLink={
                  section === "est"
                    ? `/admin/est-materials/practices/all-students/${Card.key}`
                    : section === "act"
                      ? `/admin/act-materials/practices/all-students/${Card.key}`
                      : section === "dsat"
                        ? `/admin/dsat-materials/practices/all-students/${Card.key}`
                        : `/admin/homeschooling/practices/all-students/${Card.key}}`
                }
                // isExam={isExam}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminMatrialPractises;
